import moment from "moment";

export const state = () => ({
});

export const mutations = { 
};

export const actions = { 
    /**
     * getPopupDB
     * @description: return the most recent popup that isn't expired if it exists
     * @param {object} context.rootState - the root state of the vuex store
     * @returns {Object} <Promise> popup object data or undefined if none exists
     */
    getPopupDB ({ rootState }) {
        return new Promise(async (resolve, reject) => {
            try {
                const orderString = `order=${JSON.stringify(["expiresAt","DESC"])}`;
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const { result } = await this.$axios.$get(`/popups?${orderString}`);
                const popup = _.head(result);
                // return popup if not yet expired
                if (popup && popup.isActive && moment(popup.expiresAt) > moment()) {
                    resolve(popup);
                }
                resolve();
            }
            catch (error) {
                reject(error);
            }
        });
    },
 };
