// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/png/mobile-hero.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/png/dreamydc-hero.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "header{background-color:var(--secondary-color);height:var(--header-height)}header>.logo{display:block;height:70%}#container{align-items:center;margin:0 auto;min-height:calc(100vh - var(--header-height) - var(--footer-height));width:var(--container-width)}#container>.formView{width:50%}#container>.graphic{max-height:500px;width:50%}footer{background-color:var(--secondary-color-dark);height:var(--footer-height);text-align:center}footer p{color:var(--secondary-text-color);font-size:var(--xsmaller-fontsize)}@media (max-width:599px){#container>.formView{width:100%}#container>.graphic{display:none}#backgroundContainer{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}}@media (min-width:600px){#container>.formView{width:100%}#container>.graphic{display:none}#backgroundContainer{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:cover}}@media (min-width:900px){#container>.formView{width:50%}#container>.graphic{display:block;width:50%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
