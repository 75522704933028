<template>
	<div>
        <Header @loading="isLoading=true"/>
		<LoadingOverlay :isLoading="isLoading" />
		<nuxt />
	</div>
</template>

<script>
import LoadingOverlay from "~/components/misc/LoadingOverlay";
import Header from "~/components/core/Header";
import windowLoading from "~/assets/mixins/windowLoading";
import serverError from "~/assets/mixins/serverError";

export default {
	components: {
        Header,
		LoadingOverlay,
	},
    mixins: [windowLoading, serverError],
};
</script>

<style>
.page {
	justify-content: center;
    position: relative;
}
</style>
