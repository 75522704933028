export default {
    methods: {
        /**
         * catchServerError 
         */
        catchServerError (error) {
            // Server can't be reached Error
            if (error.message === 'Network Error') {
                this.$router.push({ name: 'error' });
                return;
            } 
            // Unauthorized Error or JWT expired
            error = _.get(error.response, 'data'); 
            const errorName = _.get(error, 'name');
            if (errorName === 'Unauthorized' || errorName === 'TokenExpiredError') {
                this.$router.push({ name: 'login' });
            }
        }
    }
}