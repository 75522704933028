<template>
	<div id="menuContainer">
		<transition name="fade">
			<div v-if="isVisible" id="overlay" @click="closeMenu()"></div>
		</transition>

		<transition name="slidesideways">
			<div v-if="isVisible" id="Menu" class="flexColumn">
				<IconClose class="icon" id="closeButton" @click="closeMenu()" />

				<div id="profile" class="flexColumn flexCenter" @click="goToPage(PAGES.SETTINGS)">
					<h2>Hi {{ user.firstName }}</h2>
					<div v-if="user.imageUrl" class="avatar flexCenter">
						<img :src="user.imageUrl" class="photo" />
					</div>
					<div v-else class="avatar flexCenter">
						<IconAvatar class="placeholder" />
					</div>
					<a id="editLink">edit account</a>
				</div>
				<ul>
					<li>
						<a class="flexCenter" @click="goToPage(PAGES.SHOP)">
							<IconShop class="icon" />
							<h4>Shop</h4>
						</a>
					</li>
					<li>
						<a class="flexCenter" @click="goToPage(PAGES.DISCOUNT)">
							<IconGift class="icon" />
							<h4>Get a discount</h4>
						</a>
					</li>
					<li>
						<a class="flexCenter" href="https://dupontdispensary.com/menu" target="_blank">
							<IconSwag class="icon" />
							<h4>Menu</h4>
						</a>
					</li>
					<li>
						<a class="flexCenter" @click="logOut">
							<IconLogout class="icon" />
							<h4>Log out</h4>
						</a>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
import IconClose from "~/assets/svg/icons/icon_close.svg";
import IconSwag from "~/assets/svg/icons/icon_swag.svg";
import IconAvatar from "~/assets/svg/icons/icon_avatar.svg";
import IconShop from "~/assets/svg/icons/icon_shop.svg";
import IconGift from "~/assets/svg/icons/icon_gift.svg";
import IconLogout from "~/assets/svg/icons/icon_logout.svg";
import IconMessages from "~/assets/svg/icons/icon_messages.svg";
import { mapMutations, mapActions } from "vuex";
import { STORE, PAGES } from "~/assets/js/states";
const { MUTATIONS, ACTIONS } = STORE;

export default {
	props: {
		user: Object
	},
	components: {
        IconMessages,
		IconClose,
		IconAvatar,
		IconSwag,
		IconShop,
		IconGift,
		IconLogout
	},
	data() {
		return {
			PAGES,
			isVisible: false
		};
	},
	mounted() {
		this.isVisible = true;
	},
	methods: {
		...mapMutations({
			setMenuVisible: MUTATIONS.SET_MENU_VISIBLE,
			resetSession: MUTATIONS.RESET_SESSION,
			resetUser: MUTATIONS.RESET_USER,
			resetOrder: MUTATIONS.RESET_ORDER
		}),
		...mapActions({
			deleteSessionDB: ACTIONS.DELETE_SESSION_DB
		}),
		/**
		 * goToPage
		 * @param {string} pageName
		 */
		goToPage(pageName) {
			const callback = this.$router.push({ name: pageName });
			this.closeMenu(callback);
		},
		/**
		 * closeMenu - initiate with callback
		 * @param {function} (optional) callback
		 */
		closeMenu(callback = undefined) {
			this.isVisible = false;
			setTimeout(() => {
				this.setMenuVisible(false);
				if (callback) callback();
			}, 500);
		},
		/**
		 * resetStore
		 */
		resetStore() {
			this.resetSession();
			this.resetUser();
			this.resetOrder();
		},
		/**
		 * logOut - log the user out and go to 'login' page
		 */
		async logOut() {
            this.$emit('loading');
			const callback = async () => {
                await this.deleteSessionDB();
                this.$router.push({ name: "login" });
            };
			this.closeMenu(callback);
		}
	}
};
</script>

<style scoped>
div {
	--avatar-icon-size: 120px;
	--icon-size: 50px;
}

#menuContainer {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 1000000;
}

#menuContainer #overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(130, 130, 130, 0.96);
	z-index: 10000;
}

#Menu {
	position: absolute;
	z-index: 10000000;
	width: var(--menu-width);
	height: 100%;
	left: 0;
	top: 0;
	background-color: var(--primary-color-u);
	box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.5);
}

#Menu > #closeButton {
	position: absolute;
	cursor: pointer;
	top: 20px;
	right: 20px;
	width: 50px;
	height: 50px;
}
#Menu > #closeButton > svg {
	fill: var(--primary-text-color);
}

#Menu > #profile {
    cursor: pointer;
	margin: 150px auto 10px auto;
	width: 80%;
}

#Menu > #profile > h2 {
	margin-bottom: 30px;
	align-self: flex-start;
  color: var(--primary-text-color);
}

#Menu > #profile > #editLink {
	margin-top: 20px;
	font-size: var(--small-fontsize);
	font-weight: bold;
}
#Menu > #profile > #editLink:hover {
	color: var(--secondary-color-hover);
}

#Menu > #profile > .avatar {
	width: var(--avatar-icon-size);
	height: var(--avatar-icon-size);
	background-color: var(--secondary-color);
	border: 2px solid white;
	border-radius: 100px;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
	overflow: hidden;
}
#Menu > #profile > .avatar > .placeholder {
	width: 80%;
	height: 80%;
	fill: var(--primary-color);
}
#Menu > #profile > .avatar > .photo {
	width: 100%;
	height: 100%;
	fill: var(--primary-color);
}

#Menu > ul {
	list-style: none;
	width: 80%;
	margin: 10px auto;
}

#Menu > ul > li {
	margin: 30px 0;
}

#Menu > ul > li > a {
	text-decoration: none;
}

#Menu > ul > li > a > .icon {
	margin-right: 30px;
	width: calc(var(--medium-fontsize) + 10px);
	height: calc(var(--medium-fontsize) + 10px);
	fill: var(--primary-text-color);
}
#Menu > ul > li > a > .icon > svg {
	fill: var(--primary-text-color);
}

#Menu > ul > li > a > h4 {
	font-size: var(--medium-fontsize);
	font-weight: bold;
	width: 80%;
  color: var(--primary-text-color);
}

#Menu > ul > li > a:hover > h4,
#Menu > ul > li > a:hover > .icon {
	fill: var(--secondary-color-hover);
	color: var(--secondary-color-hover);
}

/** Media Queries **/
@media (max-width: 599px) {
	div {
		--avatar-icon-size: 100px;
		--icon-size: 40px;
	}
	#Menu > #profile {
		margin: 90px auto 10px auto;
	}
	#Menu > ul > li {
		margin: 25px 0;
	}
}
@media (min-width: 600px) {
	div {
		--avatar-icon-size: 120px;
		--icon-size: 50px;
	}
	#Menu > #profile {
		margin: 150px auto 10px auto;
	}
	#Menu > ul > li {
		margin: 30px 0;
	}
}
@media (min-width: 900px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1800px) {
}
</style>
