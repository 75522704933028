import { reject } from 'q';

const {
    STORE: { MUTATIONS, ACTIONS },
    ORDER_STATUS,
    STEPS,
} = require('~/assets/js/states');
const _ = require('lodash');

/**
 * orderStatus.js
 * 
 * Checks the customer's current Order status 
 * before loading the shop page. 
 */
export default function({ app: { $axios }, route, store, redirect }) {
    return new Promise(async(resolve, reject) => {
        
        // Function to reset store
        const resetStore = () => {
            store.commit(MUTATIONS.RESET_SESSION);
            store.commit(MUTATIONS.RESET_USER);
            store.commit(MUTATIONS.RESET_ORDER);
        }

        try {
            const customer = store.state.usersModule.user;
            const currentOrder = store.state.ordersModule.order;
            if ((currentOrder.address || currentOrder.productId) && typeof currentOrder.status === 'undefined') {
                resolve();
                return;
            } else {
                // Check for order 
                const previousOrders = await store.dispatch(ACTIONS.GET_ORDERS_DB, {
                    query: { 
                        customerId: customer.id    
                    },
                    order: ['createdAt', 'DESC'],
                    limit: 1
                });
                const lastOrder = _.head(previousOrders);

                // Return if order doesn't exist
                if (_.isEmpty(lastOrder)) {
                    store.commit(MUTATIONS.RESET_ORDER);
                    resolve();
                    return;
                }

                // Check if the most recent Order is still in progress
                switch (lastOrder.status) {
                    case ORDER_STATUS.NEW:
                    case ORDER_STATUS.IN_TRANSIT:
                    case ORDER_STATUS.NEEDS_PAYMENT:
                    case ORDER_STATUS.IN_REVIEW:
                    case ORDER_STATUS.SCHEDULED:
                        store.commit(MUTATIONS.SET_ORDER, lastOrder);
                        store.commit(MUTATIONS.SET_STEP, STEPS.CHECKOUT);
                        break;
                    case ORDER_STATUS.COMPLETED:
                    case ORDER_STATUS.CANCELLED:
                    case ORDER_STATUS.UNCONFIRMED:
                        store.commit(MUTATIONS.RESET_ORDER);
                        break;
                }
                resolve();
            }
        } catch (error) {
            resetStore();
            resolve(redirect('/login'));
        }
    });
}

