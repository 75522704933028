export default {
    data () {
        return {
            isLoading: true,
            isMounted: false,
        }
    },
    mounted () {
        this.isMounted = true;
        window.onNuxtReady((app) => {
            this.isLoading = false;
        });
        setTimeout(()=> {
            this.isLoading = false;
        }, 1000);
    }
}