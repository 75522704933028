const _ = require('lodash');

export const state = () => ({
    shop: {},
});

export const getters = {  };

export const mutations = { 
    /**
     * setShop
     * @param {object} state - default param for Vuex state 
     * @param {object} shop - the shop object
     */
    setShop (state, shop) {
        state.shop = shop;
    },
 };

export const actions = { 
    /**
     * getShopDB - retrieve the shop from the db
     * @param {object} context.state - default param for Vuex state  
     */
    getShopDB ({ commit }) {
        return new Promise(async (resolve, reject) => {
            try {
                const { result } = await this.$axios.$get(`/shops?name=${process.env.appName}`);
                const shop = _.head(result); // should only ever be one
                if (_.isEmpty(shop)) throw new Error('shop not found');
                resolve(shop);
            }
            catch (error) {
                reject(error);
            }
        });
    },
 };