<template>
    <transition name="fade-onexit" appear>
        <div 
            id="LoadingOverlay" 
            class="flexCenter"
            :class="{ fullWindow: isFullWindow }"
            v-if="isLoading"
        >   
            <IconLoader />
        </div>
    </transition>
</template>

<script>
import IconLoader from '~/assets/svg/icons/icon_loader.svg';

export default {
    props: {
        isLoading: Boolean,
        showLoader: Boolean,
        isFullWindow: Boolean
    },
    components: {
        IconLoader
    },
    mounted () {
        if (this.isFullWindow) {
            document.querySelector('body').classList.add('noscroll');
        }
    },
    destroyed () {
        if (this.isFullWindow) {
            document.querySelector('body').classList.remove('noscroll');
        }
    }
}
</script>

<style scoped>
#LoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    width: 100%;
    height: 100%;
    z-index: 500;
    overflow: hidden;
}

#LoadingOverlay.fullWindow {
    height: calc(100vh - var(--header-height));
    top: var(--header-height);
}
</style>
