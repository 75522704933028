import Vue from 'vue';
import _ from 'lodash';
import { ORDER_STATUS } from '~/assets/js/states';

export const state = () => ({
    order: {},
    orders: [],
    currentStep: undefined,
});

export const getters = {  };


export const mutations = { 
    /**
     * $_ORDERS_POST 
     * @description: adds new Order to the Orders array
     * @param {object} state - the vuex state object
     * @param {string} data - the incoming data from the io socket message in JSON string form
     * @param {string} obj.workerId - the current worker's ID
     */
    $_ORDERS_POST (state, { data, workerId }) {
        if (!data) return;
        data = JSON.parse(data);
        const newOrder = _.get(data, 'instance');
        const orders = state.orders;
        const orderIsScheduled = newOrder.status === ORDER_STATUS.SCHEDULED;
        const notifiedWorkers = _.get(newOrder, 'notifiedWorkers');
        if (_.isEmpty(notifiedWorkers) && !orderIsScheduled) return;
        if (!_.isEmpty(newOrder) && (orderIsScheduled || _.map(notifiedWorkers, 'id').includes(workerId))) {
            orders.unshift(newOrder);
            state.orders = orders;
        }
    },
    /**
     * $_ORDERS_PATCH 
     * @description: updates an Order within the Orders array
     * @param {object} state - the vuex state object
     * @param {string} obj.data - the incoming data from the io socket message in JSON string form
     * @param {string} obj.workerId - the current Worker's ID
     * @param {string} obj.orderId - the current Order's ID
     */
    $_ORDERS_PATCH (state, { data, workerId, orderId }) {
        if (!data) return;
        data = JSON.parse(data);

        const updatedOrder = _.get(data, 'instance');
        const orderIsCancelled = updatedOrder.status === ORDER_STATUS.CANCELLED;
        const notifiedWorkerIds = _.get(updatedOrder, 'notifiedWorkerIds');
        const hasNotifiedWorkers = !_.isEmpty(notifiedWorkerIds);

        if(updatedOrder.status == 'cancelled'){
            let orderIsUpdated;
            const orders = state.orders;
            orders.forEach((order, i) => {
                if (order.id === updatedOrder.id) {
                    // Set new data
                    Vue.set(state.orders, i, Object.assign({}, order, updatedOrder));
                    orderIsUpdated = true;
                }
            });
        }

        // Listen for Customer's Order updates
        if (updatedOrder.isChatMessage) {
            
            if(orderId && orderId == updatedOrder.id){
                console.log('Dispatch Customer Order Update');
                state.order = Object.assign({}, state.order, updatedOrder);
            }

            if(workerId){
                let orderIsUpdated;
                const orders = state.orders;
                orders.forEach((order, i) => {
                    if (order.id === updatedOrder.id) {
                        // Set new data
                        Vue.set(state.orders, i, Object.assign({}, order, updatedOrder));
                        orderIsUpdated = true;
                    }
                });
            }

        }
        
        // Listen for Worker's Order updates
        if (workerId && !updatedOrder.isChatMessage) {

            if (_.isEmpty(updatedOrder) || !hasNotifiedWorkers) return;
            if (!notifiedWorkerIds.includes(workerId)) return;
            let orderIsUpdated;
            const orders = state.orders;
            orders.forEach((order, i) => {
                if (order.id === updatedOrder.id) {
                    // Set new data
                    Vue.set(state.orders, i, Object.assign({}, order, updatedOrder));
                    orderIsUpdated = true;
                }
            });
            // Add new order if it's new for this worker
            if (!orderIsUpdated) {
                state.orders.unshift(updatedOrder);
            }
            // Remove scheduled orders that are cancelled from the list
            if (!hasNotifiedWorkers && orderIsCancelled) {
                state.orders = _.reject(state.orders, { id: updatedOrder.id });
            }
        }
        // Listen for Customer's Order updates
        if (orderId && !updatedOrder.isChatMessage) {
            if (updatedOrder.id === orderId) {
                state.order = Object.assign({}, state.order, updatedOrder);
            } 
        }
    },
    /**
     * setStep
     * @description: Change the order step
     * @param {object} state - default param for Vuex state 
     * @param {string} step - the step to transition to
     */
    setStep (state, step) {
        state.currentStep = step;
    },
    /**
     * setOrder 
     * @description: update a single field on the order object
     * @param {object} state - default param for Vuex state
     * @param {object} orderData - an object containing the updated fields and properties
     */
    setOrder (state, orderData) {
        state.order = Object.assign({}, state.order, orderData);
    },
    /**
     * setOrders 
     * @param {object} state - default param for Vuex state 
     * @param {object} session - the session object
     */
    setOrders (state, orders) {
        state.orders = orders;
    },
     /**
     * updateOrder 
     * @description: update a single field on the order object
     * @param {object} state - default param for Vuex state
     * @param {object} newOrderData - an object containing the updated fields and properties
     */
    updateOrder (state, newOrderData) {
        state.order = Object.assign({}, state.order, newOrderData);
        if (state.orders.length > 0) {
            state.orders.forEach((order, i) => {
                if (order.id === state.order.id) {
                    Vue.set(state.orders, i, Object.assign({}, order, newOrderData));
                }
            });
        }
    },
    /**
     * resetOrder
     * @description: clears all session values
     */
    resetOrder (state) {
        state.currentStep = undefined;
        Object.entries(state.order).forEach(([key, val])=> {
            Vue.set(state.order, key, undefined);
        });
        state.order = {};
    },
 };

export const actions = { 
    /**
     * createOrderDB 
     * @description: create the order
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     */
    createOrderDB ({state, commit, rootState}) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const response = await this.$axios.$post('/orders', state.order);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * updateOrderDB 
     * @description: update the order
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     * @param {object} order - the updated order data
     */
    updateOrderDB ({state, commit, rootState}, order) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const orderData = await this.$axios.$patch(`/orders/${order.id}`, order);
                commit('updateOrder', orderData);
                resolve(orderData);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * getPromoCodeDB 
     * @description: search DB for a promo code
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     * @return {object} promoCode - the promo code object from the DB 
     */
    getPromoCodeDB ({ state, commit, rootState }, codeName) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const { result } = await this.$axios.$get(`/promo_codes?name=${codeName}`);
                const promoCode = _.head(result);
                resolve(promoCode);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * getOrderDB - retrieve a single order from the server
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root of the vuex state
     * @param {string} payload.id - an order ID
     * @returns <Promise> orderData
     */
    getOrderDB ({ state, commit, rootState }, id) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                if (id) {
                  const order = await this.$axios.$get(`/orders/${id}`);
                  return resolve(order);
                }
                resolve({});
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * getOrdersDB 
     * @description: search DB for an order
     * @param {object} context.rootState - the root state of the vuex store
     * @param {string} query - (optional) the query values we're searching for
     * @param {array} order - (optional) the order sorting string (see Sequelize docs for formatting)
     * @param {array} limit - (optional) the max number of orders to pull (see Sequelize docs for formatting)
     * @param {array} offset - (optional) the offset index to start at (see Sequelize docs for formatting)
     * @param {boolean} isWorker - (optional) - request is for a Worker (only show orders they have been notified about)
     */
    getOrdersDB ({ commit, rootState }, { query, order, limit, offset, isWorker = false }) {
        return new Promise(async (resolve, reject) => {
            try {
                let orders, response;
                let params = '';
                if (query) {
                    const queryEntries = Object.entries(query);
                    params = queryEntries.map(([key, val]) => `${key}=${val}`).join('&');
                }
                const orderString = order ? `&order=${JSON.stringify(order)}`: '';
                const limitString = limit ? `&limit=${limit}` : '';
                const offsetString = offset ? `&offset=${offset}`: '';
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                response = await this.$axios.$get(`/orders?${params}${orderString}${limitString}${offsetString}`);
                orders = response.result;
                // If Worker - filter out only the orders they have been notified about
                if (isWorker) {
                    const userId = _.get(rootState.usersModule, 'user.id');
                    orders = _.filter(orders, order => {
                        // Show all scheduled orders
                        if (order.status === ORDER_STATUS.SCHEDULED) return true;
                        // If not scheduled, only show orders where this Worker has been notified
                        if (_.isEmpty(order.notifiedWorkers)) return false;
                        return (_.map(order.notifiedWorkers, 'id').includes(userId) || order.status !== ORDER_STATUS.NEW);
                    });
                }
                resolve(orders);
            }
            catch (error) {
                reject(error);
            }
        });
    }
 };


//  /**
//   * General Functions
//   */

//   /**
//    * updateOrderData
//    * @description: updates each field in 
//    * @param {Obhect} originalOrder
//    * @param {Object} newOrderData
//    * @returns {Object} the updated order object 
//    */
//   const updateOrderData = (originalOrder, newOrderData) => {
//     // Object.entries(newOrderData).forEach(([key, val])=> {
//     //     if (originalOrder[key] !== newOrderData[key]) {
//     //         Vue.set(originalOrder, key, val);
//     //     }
//     // });
//     return Object.assign(originalOrder, newOrderData); 
//   }