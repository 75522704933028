<template>
  <div>
    <LoadingOverlay :isLoading="isLoading" :isFullWindow="true" />
    <header class="flexCenter">
      <LogoDreamy class="logo"/>
    </header>
    <div id="backgroundContainer">
      <div
        id="container"
        class="flex"
      >
        <nuxt class="formView"/>
        <MapGraphicDC class="graphic"/>
      </div>
    </div>
    <footer class="flexCenter">
      <p> @{{ new Date().getFullYear() }} DREAMYDC. ALL RIGHTS RESERVED </p>
    </footer>
  </div>
</template>

<script>
import LogoDreamy from '~/assets/svg/misc/dreamy_logo.svg';
import MapGraphicDC from '~/assets/svg/misc/map_graphic_dc.svg';
import LoadingOverlay from '~/components/misc/LoadingOverlay';
import windowLoading from '~/assets/mixins/windowLoading';

export default {
  components: {
    LogoDreamy,
    MapGraphicDC,
    LoadingOverlay
  },
  mixins: [ windowLoading ]
}
</script>


<style>
header {
  background-color: var(--secondary-color);
  height: var(--header-height);
}
header>.logo {
  display: block;
  height: 70%;
}
#container {
    align-items: center;
    margin: 0 auto;
    width: var(--container-width);
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

}
#container>.formView {
    width: 50%;
}
#container>.graphic {
    width: 50%;
    max-height: 500px;
}
footer {
    background-color: var(--secondary-color-dark);
    height: var(--footer-height);
    text-align: center;
}
footer p {
    color: var(--secondary-text-color);
    font-size: var(--xsmaller-fontsize);
}

/** Media Queries **/
@media (max-width: 599px) {
    #container>.formView {
        width: 100%;
    }
    #container>.graphic {
        display: none;
    }
    #backgroundContainer {
      background-image: url("../assets/png/mobile-hero.png");
      background-size: cover;
    }
}
@media (min-width: 600px) {
    #container>.formView {
        width: 100%;
    }
    #container>.graphic {
        display: none;
    }
    #backgroundContainer {
      background-image: url("../assets/png/dreamydc-hero.jpg");
      background-size: cover;
    }
}
@media (min-width: 900px) {
    #container>.formView {
        width: 50%;
    }
    #container>.graphic {
        display: block;
        width: 50%;
    }
}
@media (min-width: 1200px) {
}
@media (min-width: 1800px) {
}
</style>
