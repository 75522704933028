const _ = require('lodash');

export const state = () => ({
    auth: undefined,
    session: {},
    isAuthenticated: false,
    isAuthenticating: false,
});

export const getters = { 
    /**
     * getSessionId - get ID from session in state or in localStorage
     */
    getSessionId (state) {
        const sessionId = _.get(state.session, 'id');
        return sessionId;
    },
 };

export const mutations = { 
    /**
     * setSession
     * @param {object} state - default param for Vuex state 
     * @param {object} session - the session object
     */
    setSession (state, session) {
        state.isAuthenticating = true;
        state.session = session;
    },
    /**
     * setAuthenticated 
     * @param {object} state - default param for Vuex state 
     * @param {string} obj.auth - the auth JWT 
     * @param {string} obj.id - the session ID
     */
    setAuthenticated (state, { session, auth }) {
        state.auth = auth;
        state.session = session;
        state.isAuthenticated = true;
    },
    /**
     * resetSession - clears all session values
     */
    resetSession (state) {
        state.auth = undefined;
        state.session = {};
        state.isAuthenticated = false;
        state.isAuthenticating = false;
    }
 };

export const actions = { 
    /**
     * createSessionDB - start login
     * @param {object} context.commit - for mutations
     * @param {object} obj.phoneNumber - extracted phoneNumber from param
     */
    createSessionDB ({ commit }, { phoneNumber }) {
        return new Promise(async (resolve, reject) => {
            try {
                const session = await this.$axios.$post('/sessions', {
                    phoneNumber
                });
                // Save the session to vuex store
                commit('setSession', session);
                resolve();
            } 
            catch (error) {
                console.log({error});
                reject(error);
            }
        });
    },
    /**
     * authenticateSessionDB - attempt login
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {string} pin - the pin used to verify the session
     */
    authenticateSessionDB ({ state, commit }, pin) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data, headers } = await this.$axios.patch(`/sessions/${state.session.id}`, { pin });
                // Set authenticated 
                commit('setAuthenticated', {
                    session: data,
                    auth: headers.auth
                });
                resolve(data);
            } 
            catch (error) {
                reject(error);
            }
        }); 
    },
    /**
     * deleteSessionDB - log out
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root of the vuex state
     */
    deleteSessionDB ({ state, commit, rootState }) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const { data, headers } = await this.$axios.delete(`/sessions/${state.session.id}`);
                // Remove all session data 
                commit('resetSession');
                resolve();
            } 
            catch (error) {
                reject(error);
            }
        }); 
    }
 };