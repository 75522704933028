
module.exports = {
    ROUTES: {
        HOME: 'index',
        LOGIN: 'login',
        SIGNUP: 'signup',
        VERIFY: 'verify',
        SHOP: 'shop',
        MESSAGES: 'messages',
        SETTINGS: 'settings',
        DISCOUNT: 'discount',
        WORKER: 'worker',
        ORDER: 'order',
        ERROR: 'error'
    },
    DAYS: {
        TODAY: 'today',
        TOMORROW: 'tomorrow'
    },
    STEPS: {
        LOCATION: 'location',
        PRODUCT: 'product',
        SCHEDULE: 'schedule',
        CHECKOUT: 'checkout'
    },
    ORDER_STATUS: {
        NEW: 'new',
        SCHEDULED: 'scheduled',
        IN_TRANSIT: 'in-transit',
        NEEDS_PAYMENT: 'needs-payment',
        IN_REVIEW: 'in-review',
        COMPLETED: 'completed',
        CANCELLED: 'cancelled',
        UNCONFIRMED: 'unconfirmed',
    },
    ORDER_TYPES: {
        SCHEDULED_TOMORROW: 'scheduled-tomorrow',
        SCHEDULED_TODAY: 'scheduled-today',
    },
    SURGE_TYPES: {
        WEATHER: 'weather',
        OVERLOAD: 'overload',
        LATE_TIME: 'late-time',
        REALLY_LATE_TIME: 'really-late-time',
        EARLY_TIME: 'early-time'
    },
    CHECKOUT_STATUS: {
        CONFIRMATION: 'confirmation',
        PROCESSING: 'processing',
        IN_REVIEW: 'in-review'
    },
    ORDER_FIELDS: {
        PRODUCT_ID: 'productId',
        PRODUCT_NAME: 'productName',
        ADDRESS: 'address',
        COORDS: 'coords',
        IS_SCHEDULED: 'isScheduled',
        SCHEDULED_TIME: 'scheduledTime'
    },
    WORKER_ORDER_STEPS: {
        INFO: "info",
        QUANTITY: "quantity",
        PAYMENT: "payment"
    },
    WORKER_ORDER_COMMANDS: {
        ACCEPT: 'accept',
        PAID: 'paid',
        CANCEL: 'cancel',
        COMPLETE: 'complete',
        TRANSFER: 'transfer'
    },
    REVIEW_STEPS: {
        RATING: 'rating',
        COMMENTS: 'comments',
        READY: 'ready',
        COMPLETED: 'completed'
    },
    ORDER_RATING: {
        THUMBS_UP: 'up',
        THUMBS_DOWN: 'down'
    },
    GOOD_COMMENTS: [
        'Fast',
        'Communication',
        'Quality',
        'Friendly'
    ],
    BAD_COMMENTS: [
        'Slow', 
        'Communication',
        'Quality',
        'Rude'
    ],
    ROLES: {
        CUSTOMER: 'customer',
        WORKER: 'worker',
        ADMIN: 'admin'
    },
    PAGES: {
        SHOP: 'shop',
        SETTINGS: 'settings',
        DISCOUNT: 'discount'
    },
    STORE: {
        GETTERS: {
            GET_SESSION_ID: 'sessionsModule/getSessionId',
        },
        MUTATIONS: {
            SET_MENU_VISIBLE: 'mapModule/setMenuVisible',
            SET_SESSION: 'sessionsModule/setSession',
            SET_AUTHENTICATED: 'sessionsModule/setAuthenticated',
            SET_ADDRESS_GEOCODER: 'mapModule/setAddressGeocoder',
            SET_STEP: 'ordersModule/setStep',
            SET_SHOP: 'shopsModule/setShop',
            SET_USER: 'usersModule/setUser',
            SET_ORDER: 'ordersModule/setOrder',
            SET_ORDERS: 'ordersModule/setOrders',
            SET_PRODUCTS: 'productsModule/setProducts',
            UPDATE_MAP_LOCATION: 'mapModule/updateMapLocation',
            UPDATE_ORDER: 'ordersModule/updateOrder',
            UPDATE_USER: 'usersModule/updateUser',
            RESET_SESSION: 'sessionsModule/resetSession',
            RESET_USER: 'usersModule/resetUser',
            RESET_ORDER: 'ordersModule/resetOrder',
            SOCKETS: {
                ORDERS_POST: 'ordersModule/$_ORDERS_POST',
                ORDERS_PATCH: 'ordersModule/$_ORDERS_PATCH',
            }
        },
        ACTIONS: {
            CREATE_SESSION_DB: 'sessionsModule/createSessionDB',
            CREATE_ORDER_DB: 'ordersModule/createOrderDB',
            CREATE_USER_DB: 'usersModule/createUserDB',
            CREATE_REVIEW_DB: 'reviewsModule/createReviewDB',
            AUTHENTICATE_SESSION_DB: 'sessionsModule/authenticateSessionDB',
            EMAIL_VERIFICATION_DB: 'usersModule/emailVerificationDB',
            UPDATE_USER_DB: 'usersModule/updateUserDB',
            UPDATE_USER_IMAGE_DB: 'usersModule/updateUserImageDB',
            UPDATE_ORDER_DB: 'ordersModule/updateOrderDB',
            GET_SHOP_DB: 'shopsModule/getShopDB',
            GET_USER_DB: 'usersModule/getUserDB',
            GET_ORDER_DB: 'ordersModule/getOrderDB',
            GET_QUOTE_DB: 'quotesModule/getQuoteDB',
            GET_PRODUCTS_DB: 'productsModule/getProductsDB',
            GET_POPUP_DB: 'popupsModule/getPopupDB',
            GET_PROMO_CODE_DB: 'ordersModule/getPromoCodeDB',
            GET_ORDERS_DB: 'ordersModule/getOrdersDB',
            GET_USERS_DB: 'usersModule/getUsersDB',
            DELETE_SESSION_DB: 'sessionsModule/deleteSessionDB',
        },
    }
}