
export const state = () => ({
    products: [],
});

export const mutations = { 
    /**
     * setProducts 
     * @param {object} state - default param for Vuex state 
     * @param {array} products - the products available
     */
    setProducts (state, products) {
        state.products = products;
    },
 };

export const actions = { 
    /**
     * getProductsDB - retrieve the available products from the server
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     * @param {string} query - the query object
     * @return {array} products - the available products from the DB 
     */
    getProductsDB ({ state, commit, rootState }, query) {
        return new Promise(async (resolve, reject) => {
            try {
                let params = '';
                if (query) {
                    const queryEntries = Object.entries(query);
                    params = queryEntries.map(([key, val]) => `${key}=${val}`).join('&');
                }
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const { result } = await this.$axios.$get(`/products?${params}`);
                resolve(result);
            }
            catch (error) {
                reject(error);
            }
        });
    },
 };