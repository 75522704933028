import Vue from 'vue';
import { Promise } from 'q';
import createPersistedState from 'vuex-persistedstate';

export const state = () => ({});
export const getters = {};
export const mutations = {
    SOCKET_CONNECT () {
        console.log('CONNECTED');
    }
};
export const actions = {};
export const plugins = [createPersistedState({ key: 'data' })];
export const strict = false;
