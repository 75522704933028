<template>
    <header id="Header">
        <div id="header-container">
            <!-- Customer Only - Menu hamburger button -->
            <div
                v-if="isCustomer && isAuthenticated"
                id="menuButton"
                class="align-left flexCenter"
                @click="menuButtonClickHandler"
            >
                <IconMenu class="icon" />
            </div>
            <!-- Worker Only - Back button -->
            <div
                v-if="isWorker"
                id="backButton"
                class="align-left flexCenter"
                :class="{ isVisible: isOnOrderPage }"
                @click="backButtonClickHandler"
                @click.shift="menuButtonShiftClickHandler"
            >
                <IconBackArrow class="icon" />
            </div>
            <!-- Logo -->
            <nuxt-link
                class="logo"
                :class="{ clickable: isNotOnShopPage }"
                to="/"
            >
                <LogoDreamy />
            </nuxt-link>
            <!-- Customer Only - Discount page button -->
            <nuxt-link
                v-if="isCustomer && isAuthenticated"
                id="giftButton"
                class="align-right flexCenter"
                :class="{ isVisible: isNotOnDiscountPage }"
                to="/discount"
            >
                <IconGift class="icon" />
            </nuxt-link>
            <!-- Worker Only - Logout button -->
            <div
                v-if="isWorker"
                id="logoutButton"
                class="align-right flexCenter"
                @click="logOutHandler"
            >
                <IconLogout class="icon" />
            </div>
        </div>
        <!-- Customer Only - Menu component -->
        <MenuComponent
            v-if="isCustomer && isMenuVisible && isAuthenticated"
            :user="user"
            @loading="$emit('loading')"
        />
    </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import LogoDreamy from "~/assets/svg/misc/dreamy_logo.svg";
import IconLogout from "~/assets/svg/icons/icon_logout.svg";
import IconMenu from "~/assets/svg/icons/icon_menu.svg";
import IconGift from "~/assets/svg/icons/icon_gift.svg";
import IconMessages from "~/assets/svg/icons/icon_messages.svg";
import IconBackArrow from '~/assets/svg/icons/icon_back_arrow.svg';
import MenuComponent from "~/components/shop/Menu";
import { STORE, ROLES, WORKER_ORDER_STEPS } from "~/assets/js/states";
const { MUTATIONS, ACTIONS } = STORE;

export default {
	components: {
        IconMenu,
        IconGift,
        IconMessages,
        IconLogout,
        IconBackArrow,
        LogoDreamy,
		MenuComponent
    },
    data() {
      return {};
    },
    beforeMount() {
		/* Reset Menu visibility */
		this.setMenuVisible(false);
    },
    methods: {
		...mapMutations({
            setStep: MUTATIONS.SET_STEP,
			setMenuVisible: MUTATIONS.SET_MENU_VISIBLE
        }),
        ...mapActions({
			deleteSessionDB: ACTIONS.DELETE_SESSION_DB
		}),
		/**
		 * menuButtonClickHandler
         * @description: toggle menu component
		 */
		menuButtonClickHandler() {
			this.setMenuVisible(true);
        },
        menuButtonShiftClickHandler() {
            myUndefinedFunction();
        },
        /**
         * backButtonClickHandler
         * @description: go back in Order step or to Worker page
         */
        backButtonClickHandler () {
            this.goBackStep();
        },
        /**
		 * goBackStep
         * @description: update which wiget is shown to the Worker
		 */
		goBackStep() {
			if (this.currentStep === WORKER_ORDER_STEPS.PAYMENT) {
				this.setStep(WORKER_ORDER_STEPS.QUANTITY);
            } else if (this.currentStep === WORKER_ORDER_STEPS.QUANTITY) {
				this.setStep(WORKER_ORDER_STEPS.INFO);
            } else if (this.currentStep === WORKER_ORDER_STEPS.INFO) {
                this.goBackPage();
            }
		},
		/**
		 * goBackPage
         * @description: go back to the ALL ORDERS (index) page
		 */
		goBackPage() {
			this.$router.push({ name: 'worker' });
		},
        /**
		 * logOut
         * @description: log the user out and go to 'login' page
		 */
		async logOutHandler() {
			await this.deleteSessionDB();
            this.$router.go('login');
		}
	},
	computed: {
		...mapState({
            currentStep: state => state.ordersModule.currentStep,
            isMenuVisible: state => state.mapModule.isMenuVisible,
            isAuthenticated: state => state.sessionsModule.isAuthenticated,
			user: state => state.usersModule.user,
            order: state => state.ordersModule.order
        }),
        /**
         * isCustomer
         * @description: check if user is a customer
         */
        isCustomer () {
            return this.user.role === ROLES.CUSTOMER
        },
        /**
         * isWorker
         * @description: check if user is a worker
         */
        isWorker () {
            return this.user.role === ROLES.WORKER
        },
        /**
         * isOnOrderPage
         * @description: determine if the page is an Order page
         */
        isOnOrderPage () {
            return this.$route.name.includes('order');
        },
        /**
         * isNotOnShopPage
         * @description: determine if the page is an Shop page
         */
        isNotOnShopPage () {
            if(this.$route.name !== null){
                return !this.$route.name.includes('shop');
            }
        },
        /**
         * isNotOnDiscountPage
         * @description: determine if the page is the Discount page
         */
        isNotOnDiscountPage () {
            if(this.$route.name !== null){
                return !this.$route.name.includes('discount');
            }
        }
	}
}
</script>

<style>
#Header {
    background-color: var(--secondary-color-u);
    position: relative;
	  height: var(--header-height);
    align-items: center;
    width: 100%;
}
#Header #header-container {
    position: relative;
    height: 100%;
    width: 94vw;
    margin: 0 auto;
}
#Header .align-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
#Header .align-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
#Header .logo {
    cursor: default;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 70%;
    max-width: 200px;
    transform: translate(-50%, -50%);
}
#Header .logo.clickable {
    cursor: pointer;
}
#Header .logo > svg {
    height: 100%;
    display: block;
    margin: 0 auto;
    fill: var(--primary-text-color);
    color: var(--primary-text-color);
}
#Header .icon {
    cursor: pointer;
	width: 40px;
    fill: var(--primary-text-color);
}
#Header .icon:hover {
    fill: var(--primary-color-hover);
    transform: scale(1.1);
}
#Header #backButton,
#Header #giftButton {
    visibility: hidden;
    opacity: 0;
    transition: all 1s ease;
}
#Header #backButton.isVisible,
#Header #giftButton.isVisible {
    visibility: visible;
    opacity: 1;
}

#Header #messagesButton{
    margin-right: 50px;
}

#Header #messagesButton{
    fill: var(--primary-text-color);
    color: var(--primary-text-color);
    padding-top: 5px!important;
}


/** Media Queries **/
@media (max-width: 599px) {
    #Header .icon {
        width: 30px;
        margin: 0 15px;
    }
}
@media (min-width: 600px) {
    #Header .icon {
        width: 32px;
        margin: 0 18px;
    }
}
@media (min-width: 900px) {
    #Header .icon {
        width: 35px;
        margin: 0 22px;
    }
}
@media (min-width: 1200px) {
}
@media (min-width: 1800px) {
}
</style>
