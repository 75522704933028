
export const actions = { 
    /**
     * getQuoteDB - retrieve a quote from the server by ID
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     * @param {string} query.key - (optional) the name of the indexed field to search
     * @param {string} query.value - (optional) the value we're searching for
     * @return {array} products - the available products from the DB 
     */
    getQuoteDB ({ state, commit, rootState }, id) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const quote = await this.$axios.$get(`/quotes/${id}`);
                resolve(quote);
            }
            catch (error) {
                reject(error);
            }
        });
    },
 };