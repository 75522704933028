import Vue from 'vue';

export const state = () => ({
        user: {},
});

export const mutations = { 
    /**
     * setUser
     * @param {object} state - default param for Vuex state 
     * @param {object} user - the user data
     */
    setUser (state, user) {
        state.user = user;
    },
    /**
     * updateUser - update a single field on the user object
     * @param {object} state - default param for Vuex state
     * @param {object} newUserData - an object containing the updated fields and properties
     */
    updateUser (state, newUserData) {
        Object.entries(newUserData).forEach(([key, val])=> {
            Vue.set(state.user, key, val);
        });
    },
    /**
     * resetUser - clears all session values
     */
    resetUser (state) {
        state.user = {};
    }
 };

export const actions = { 
    /**
     * createUserDB - create the user
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     */
    createUserDB ({state}, user) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.$axios.$post('/users', user);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * emailVerificationDB
     * @param {string} user.email
     * @param {string} user.emailToken
     * @returns <Promise> is email verified?
     */
    emailVerificationDB ({}, { email, emailToken }) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.$axios.$post('/email_verifications', {
                    email,
                    emailToken
                });
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
    /**
     * getUserDB - retrieve the user info from the server
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root of the vuex state
     * @param {string} id - (optional) a user ID
     * @returns <Promise> userData
     */
    getUserDB ({ state, commit, rootState }, id = undefined) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                if (!id) id = rootState.sessionsModule.session.userId;
                const user = await this.$axios.$get(`/users/${id}`);
                resolve(user);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * getUsersDB - search DB for users
     * @param {object} context.rootState - the root state of the vuex store
     * @param {string} query.key - (optional) the name of the indexed field to search
     * @param {string} query.value - (optional) the value we're searching for
     * @return {array} users - the available users from the DB 
     */
    getUsersDB ({ commit, rootState }, { query, order, limit, offset }) {
        return new Promise(async (resolve, reject) => {
            try {
                let users, response;
                let params = '';
                if (query) {
                    const queryEntries = Object.entries(query);
                    params = queryEntries.map(([key, val]) => `${key}=${val}`).join('&');
                }
                const orderString = order ? `&order=${JSON.stringify(order)}`: '';
                const limitString = limit ? `&limit=${limit}` : '';
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                response = await this.$axios.$get(`/users?${params}${orderString}${limitString}`);
                users = response.result;
                resolve(users);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * updateUserDB - update the User's database info
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} userData - subset of the User object with updated data
     * @returns <Promise> userData
     */
    updateUserDB ({ state, commit, rootState }, userData) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                const user = await this.$axios.$patch(`/users/${state.user.id}`, {
                    ...userData
                });
                resolve(user);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    /**
     * updateUserImageDB - update the User's image
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} formData
     * @returns <Promise> userData
     */
    updateUserImageDB ({ state, commit, rootState }, formData) {
        return new Promise(async (resolve, reject) => {
            try {
                this.$axios.setHeader('Auth', rootState.sessionsModule.auth);
                this.$axios.setHeader('Content-Type', 'multipart/form-data');
                const user = await this.$axios.$patch(`/users/${state.user.id}/image`, formData);
                resolve(user);
            }
            catch (error) {
                reject(error);
            }
        });
    }
 };
