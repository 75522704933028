export const state = () => ({
    addressGeocoder: undefined,
    mapMarkerLocation: undefined,
    isMenuVisible: false,
})

export const mutations = {
    /**
     * setAddressGeocoder - on map load, save the geocoder object to an app-wide variable
     * @param {object} state - default param for Vuex state 
     * @param {object} geocoder - the geocoder object
     */
    setAddressGeocoder (state, geocoder) {
        state.addressGeocoder = geocoder;
    },
    /**
     * updateMapLocation - attempt to change the map's marker position
     * @param {object} state - default param for Vuex state 
     * @param {object} coords - the location to change to in coordinate form: { latitude, longitude }
     */
    updateMapLocation (state, coords) {
        state.mapMarkerLocation = coords; 
    },
    /**
     * setMenuVisible - alter the `isMenuVisible` state variable
     * @param {object} state - default param for Vuex state 
     * @param {boolean} val - the new variable value
     */
    setMenuVisible (state, val) {
        state.isMenuVisible = val;
    } 
}