export const state = () => ({
});

export const mutations = { 
};

export const actions = { 
    /**
     * createReviewDB - create the review
     * @param {object} context.state - Vuex state 
     * @param {object} context.commit - for mutations
     * @param {object} context.rootState - the root state of the vuex store
     */
    createReviewDB ({state}, review) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.$axios.$post('/reviews', review);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    },
 };
