const { 
    STORE: { MUTATIONS, ACTIONS },
    ROUTES,
    ROLES
} = require('~/assets/js/states');
const _ = require('lodash');

/**
 * authentication.js
 * 
 * Ensures the user is authenticated before navigating to any route.
 * If not authenticated, the user is redirected to proper routes.
 * 
 * @param {object} context.app.$axios - the base Axios module
 * @param {object} context.route - the vue route object 
 * @param {object} context.store - the vuex store
 * @param {function} redirect - the function we return for redirects
 */
export default function ({ route, store, redirect }) {
    return new Promise(async resolve => {
        const sessionId = _.get(store.state.sessionsModule, 'session.id');
        let shop;
        let user;

        /**
         * resetStore 
         * @description reset store data
         */
        const resetStore = () => {
            store.commit(MUTATIONS.RESET_SESSION);
            store.commit(MUTATIONS.RESET_USER);
            store.commit(MUTATIONS.RESET_ORDER);
        }

        // If a session ID is found
        if (sessionId) {

            const { 
                isAuthenticating, 
                isAuthenticated, 
            } = store.state.sessionsModule;

            /** 
             * assertRouteUnauthenticated
             * @description ensure route is unauthenticated
             */
            const assertRouteUnauthenticated = () => {
                switch (route.name) {
                    case ROUTES.ERROR:
                        break;
                    case ROUTES.LOGIN:
                    case ROUTES.SIGNUP:
                    case ROUTES.VERIFY:
                        resolve();
                        break;
                    default: 
                        rerouteLogin();
                        break;
                }
            }

            /**
             * rerouteLogin
             * @description clear store data and re-route to login page
             */
            const rerouteLogin = () => {
                resetStore();
                resolve(redirect('/login'));
            }

            try {

                // If not authenticated, check route and go
                if (!isAuthenticated) {
                    assertRouteUnauthenticated();
                    return;
                }

                // Get Shop
                const shop = await store.dispatch(ACTIONS.GET_SHOP_DB);
                // Get User
                const user = await store.dispatch(ACTIONS.GET_USER_DB);
                const isCustomer = user.role === ROLES.CUSTOMER;
                const isWorker = user.role === ROLES.WORKER;
                // Set store data
                store.commit(MUTATIONS.SET_SHOP, shop);
                store.commit(MUTATIONS.SET_USER, user);


                /**
                 * checkCustomerRoute
                 * @description customer route verification
                 */
                const checkCustomerRoute = () => {
                    if (isAuthenticated && isCustomer) resolve();
                    else if (isAuthenticated && isWorker) resolve(redirect('/worker'));
                    else rerouteLogin();
                }

                /**
                 * checkWorkerRoute
                 * @description worker route verification
                 */
                const checkWorkerRoute = () => {
                    if (isAuthenticated && isWorker) resolve();
                    else if (isAuthenticated && isCustomer) resolve(redirect('/shop'));
                    else rerouteLogin();
                }

                // User or Shop empty?
                if (_.isEmpty(shop) || _.isEmpty(user)) {
                    assertRouteUnauthenticated();
                    return;
                }


                // Check route
                switch (route.name) {
                    case ROUTES.HOME: 
                        if (isAuthenticated && isCustomer) resolve(redirect('/shop'));
                        else if (isAuthenticated && isWorker) resolve(redirect('/worker'));
                        else resolve(redirect('/login'));
                        break;
                    case ROUTES.LOGIN:
                    case ROUTES.SIGNUP:
                    case ROUTES.VERIFY:
                        if (isAuthenticated && isCustomer) resolve(redirect('/shop'));
                        else if (isAuthenticated && isWorker) resolve(redirect('/worker'));
                        else resolve();
                        break;
                    case ROUTES.SHOP: 
                    case ROUTES.SETTINGS:
                    case ROUTES.DISCOUNT:
                        checkCustomerRoute();
                        break;
                    case ROUTES.WORKER:
                    case ROUTES.ORDER:
                        checkWorkerRoute();
                        break;
                    case ROUTES.ERROR:
                        await store.dispatch(ACTIONS.GET_SHOP_DB);
                        if (isAuthenticated && isCustomer) resolve(redirect('/shop'));
                        else if (isAuthenticated && isWorker) resolve(redirect('/worker'));
                        else if (isAuthenticating) resolve(redirect('/verify'));
                        else rerouteLogin();
                        break;
                }
            }

            // If the user is NOT authenticated
            catch (error) {
                // Make sure we're not having a server error
                if (error.message === 'Network Error') {
                    resolve(redirect('/error'));
                }
                // Check if User is trying to login
                if (isAuthenticating) {
                    assertRouteUnauthenticated();
                } else {
                    rerouteLogin();
                }
            }
        } 
        
        // If a session ID is NOT found
        else {
            resetStore();
            // Redirect if needed
            switch (route.name) {
                case ROUTES.LOGIN:
                case ROUTES.SIGNUP:
                    resolve();
                    break;
                case ROUTES.ERROR: 
                    try {
                        await store.dispatch(ACTIONS.GET_SHOP_DB);
                        resolve(redirect('/login'));
                    } catch (error) {
                        resolve();
                    }
                    break;
                default: 
                    resolve(redirect('/login'));
            }
        }
    });
}